import './index.css';
import App from './App';
import env from './env.json';
import ReactDOM from 'react-dom/client';
import connectors from './web3/connectors';
import { Web3ReactProvider } from '@web3-react/core';
import { Toaster, DefaultToastOptions } from 'react-hot-toast';
import { WalletProvider } from './web3/providers/WalletProvider';
import { ContractProvider } from './web3/providers/ContractProvider';

const toastOptions: DefaultToastOptions = {
  success: {
    style: {
      background: 'var(--color-success)',
      color: 'var(--color-success-contrast)'
    },
    iconTheme: {
      primary: 'var(--color-success-contrast)',
      secondary: 'var(--color-success)'
    }
  },
  error: {
    style: {
      background: 'var(--color-error)',
      color: 'var(--color-error-contrast)'
    },
    iconTheme: {
      primary: 'var(--color-error-contrast)',
      secondary: 'var(--color-error)'
    }
  }
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Web3ReactProvider connectors={connectors} lookupENS>
    <WalletProvider chainId={env.chain}>
      <ContractProvider>
        <App />
        <Toaster toastOptions={toastOptions} />
      </ContractProvider>
    </WalletProvider>
  </Web3ReactProvider>
);