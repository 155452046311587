interface ButtonProps {
    text: React.ReactNode;
    onClick: () => void;
    primary?: boolean;
    disabled?: boolean;
    highlighted?: boolean;
}

export function Button(props: ButtonProps) {
    const colors = props.primary ? "bg-accent text-accent-contrast" : "bg-primary text-accent";
    const animation = props.highlighted ? "animate-pulse" : "";

    return (
        <button
            children={props.text}
            onClick={props.onClick}
            disabled={props.disabled}
            className={`condensed select-none text-md rounded-full p-2 pl-4 pr-4 text-center disabled:opacity-30 font-semibold ${animation} ${colors}`}
        />
    );
}