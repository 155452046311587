import env from '../../env.json';
import Abi from '../../contract.json';
import { Contract } from '@ethersproject/contracts';
import type { Web3Provider } from '@ethersproject/providers';

export class ContractService {
    constructor(private provider?: Web3Provider) { }

    hasProvider(): boolean {
        if (!env.contract_address || env.contract_address === "") return false;
        return this.provider !== undefined && this.provider !== null;
    }

    symbol(): Promise<string> {
        return this.contract().symbol();
    }

    paused(): Promise<boolean> {
        return this.contract().paused();
    }

    price(): Promise<number> {
        return this.contract().price();
    }

    async mint(account: string): Promise<string> {
        const price = await this.price();
        const mintParams = { from: account, value: "" + price };
        const send = this.contract().methods.mint();
        const gasEstimate = await send.estimateGas(mintParams);

        const receipt = await send.send({
            ...mintParams,
            gas: "" + gasEstimate
        });
        return receipt.transactionHash;
    }

    contract(): Contract {
        if (!this.provider) {
            throw 'Provider not initialized';
        }
        const abi: any = Abi;
        const contract = new Contract(env.contract_address, abi);
        return contract.connect(this.provider);
    }
}