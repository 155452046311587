export function formatShortAddress(address: string): string {
    const start = address.substring(0, 5);
    const end = address.substring(address.length - 4, address.length);
    return `${start}...${end}`;
}

export function formatShortEns(ens?: string): string | undefined {
    if (!ens) { return undefined; }
    if (ens.length <= 16) { return ens; }
    const start = ens.substring(0, 10);
    return `${start}...eth`;
}

export function formatEth(wei: number): string {
    const eth = wei / 1000000000000000000;
    return `${eth} ETH`;
}