import { useContract } from "../../web3/providers/ContractProvider";
import { formatEth } from "../../web3/services/Formatters";

export function ContractInfo() {
    const { networkStatus, symbol, price } = useContract();

    return (
        <div className="grid grid-cols-2 gap-x-3 gap-y-1">
            <b>Network status:</b>
            <p>{networkStatus}</p>
            <b>Contract token:</b>
            <p>{symbol}</p>
            <b>Contract price:</b>
            <p>{formatEth(price)}</p>
        </div>
    );
}