import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { ContractService } from '../services/ContractService';

export enum NetworkStatus {
    LOADING = "LOADING",
    AVAILABLE = "AVAILABLE",
    UNKNOWN = "UNKNOWN"
}

export interface ContractMethods {
    networkStatus: NetworkStatus;
    symbol: string;
    paused: boolean;
    price: number;
    mint: (account: string) => Promise<string>;
}

const ContractContext = React.createContext<ContractMethods>({
    networkStatus: NetworkStatus.LOADING,
    symbol: "",
    paused: false,
    price: 0,
    mint: async () => "",
});

export const ContractProvider = (props: { children: React.ReactNode }) => {
    const { provider } = useWeb3React();

    const [networkStatus, setNetworkStatus] = React.useState<NetworkStatus>(NetworkStatus.LOADING);
    const [symbol, setSymbol] = React.useState<string>("");
    const [paused, setPaused] = React.useState<boolean>(false);
    const [price, setPrice] = React.useState<number>(0);

    const refreshCallback = React.useCallback(async () => {
        try {
            const service = new ContractService(provider);

            if (!service.hasProvider()) {
                setNetworkStatus(NetworkStatus.UNKNOWN);
                return;
            }

            await service.symbol().then(setSymbol);
            await service.paused().then(setPaused);
            await service.price().then(setPrice);

            setNetworkStatus(NetworkStatus.AVAILABLE);
        } catch {
            setNetworkStatus(NetworkStatus.UNKNOWN);
        }
    }, [provider, setSymbol, setPaused, setPrice, setNetworkStatus]);

    React.useEffect(() => {
        refreshCallback();
        const intervalId = setInterval(() => {
            refreshCallback();
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [refreshCallback]);

    const mint = async (account: string) => {
        if (paused) {
            throw 'Mint is paused';
        }
        return await new ContractService(provider).mint(account);
    };

    return (
        <ContractContext.Provider value={{
            networkStatus,
            symbol,
            paused,
            price,
            mint,
        }}>
            {props.children}
        </ContractContext.Provider>
    );
};

export function useContract(): ContractMethods {
    return React.useContext(ContractContext);
}