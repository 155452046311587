import { ContractInfo } from "./mint/components/ContractInfo";
import { WalletConnectButton } from "./web3/components/WalletConnectButton";

export default function App() {
  return (
    <div className="flex-1 flex flex-col justify-center items-center space-y-5">
      <p className="condensed">web3 template</p>
      <WalletConnectButton />
      <ContractInfo />
    </div>
  );
}