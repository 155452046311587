import { Button } from "../../util/components/Button";
import { WalletMethods, useWallet } from "../providers/WalletProvider";
import { formatShortAddress, formatShortEns } from "../services/Formatters";

export function WalletConnectButton(props: { text?: string }) {
    const wallet = useWallet();

    const onClick = () => {
        if (wallet.connected) {
            wallet.disconnect();
        } else {
            wallet.connect();
        }
    };

    return <Button text={props.text || caption(wallet)} onClick={onClick} highlighted={!wallet.connected || !wallet.account} primary />;
}

function caption(wallet: WalletMethods) {
    if (wallet.connected && wallet.account) {
        return formatShortEns(wallet.accountEns) || formatShortAddress(wallet.account);
    } else if (wallet.connecting) {
        return "connecting ...";
    } else {
        return "connect wallet";
    }
}