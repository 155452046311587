import React from 'react';
import toast from 'react-hot-toast';
import { useWeb3React } from '@web3-react/core';

export interface WalletMethods {
    connecting: boolean;
    connected: boolean;
    account?: string;
    accountEns?: string;
    connect: () => void;
    disconnect: () => void;
    lookupEns: (address: string) => Promise<string | undefined>;
}

const WalletContext = React.createContext<WalletMethods>({
    connecting: false,
    connected: false,
    account: undefined,
    accountEns: undefined,
    connect: () => { },
    disconnect: () => { },
    lookupEns: async () => undefined,
});

export const WalletProvider = (props: { children: React.ReactNode, chainId: number }) => {
    const { connector, hooks, provider } = useWeb3React();
    const { useSelectedIsActivating, useSelectedIsActive, useSelectedAccount, useSelectedENSName } = hooks;

    const isActivating = useSelectedIsActivating(connector);
    const connected = useSelectedIsActive(connector);
    const account = useSelectedAccount(connector);
    const accountEns = useSelectedENSName(connector, provider);

    const [connecting, setConnecting] = React.useState(false);

    const connect = async () => {
        if (!isActivating && !connecting) {
            setConnecting(true);

            try {
                await toast.promise(Promise.resolve(connector.activate(props.chainId)), {
                    loading: 'Connecting to wallet...',
                    success: 'Connected!',
                    error: (err) => humanizeError(err, props.chainId)
                });
            } catch (e: any) {
                connector.resetState();
                if ((e instanceof Error || e.message) && e.message.includes('installed')) {
                    const website = window.location.hostname;
                    window.open(`https://metamask.app.link/dapp/${website}/`, "_blank");
                }
            } finally {
                setConnecting(false);
            }
        }
    };

    const disconnect = async () => {
        if (connector.deactivate) {
            await connector.deactivate()
        } else {
            await connector.resetState()
        }
    };

    const lookupEns = async (address: string): Promise<string | undefined> => {
        if (!provider) { return undefined; }
        return (await provider.lookupAddress(address)) || undefined;
    };

    React.useEffect(() => {
        if (connector.connectEagerly) {
            connector.connectEagerly(props.chainId);
        }
    }, [connector]);

    return (
        <WalletContext.Provider value={{ connecting, connected, account, accountEns: accountEns || undefined, connect, disconnect, lookupEns }}>
            {props.children}
        </WalletContext.Provider>
    );
};

export function useWallet(): WalletMethods {
    return React.useContext(WalletContext);
}

function humanizeError(e: any, chainId: number): string {
    if (e instanceof Error || e.message) {
        if (e.message.includes('No Ethereum provider was found')) {
            return "Please install MetaMask!";
        } else if (e.message.includes('Unsupported chain')) {
            return `Please switch to the ${getChainName(chainId)} network!`;
        } else {
            return e.message;
        }
    }
    return `${e}`;
}

function getChainName(chainId: number): string {
    switch (chainId) {
        case 1: return 'Ethereum main';
        case 5: return 'Ethereum Goerli test';
        case 11155111: return 'Ethereum Sepolia test';
        default: return 'correct';
    }
}